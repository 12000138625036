import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import ContactFormComponent from "../../components/pages/shared/contact-form"
import styles from "./quarantine-offer.module.scss"
import img from "./quarantine-offer.png"
import { openOverlay } from "../../utils/overlay"

const QuarantineOfferPage = () => (
  <Layout page={"special-quarantine-offer"} forceFixedHeader={true}>
    <SEO title={"Бесплатный доступ в Параплан до конца июня"}
         description={"Оформите бесплатный доступ в Параплан до 30 июня 2020 года"}
         keywords={[`акция`, `бесплатный доступ`, `карантин`, `crm-система Параплан`]} noindex="noindex"/>
    <div className={`container ${styles.blogPost}`}>
      <h1 className={styles.title}>Используйте Параплан бесплатно до конца июня!</h1>
      <img alt={""} src={img} className={styles.image}/>
      <div className={styles.body}>
        <p>Дорогие друзья,</p>
        <p>мы понимаем какая сейчас непростая ситуация на рынке детских и спортивных центров, а также других организаций
          дополнительного образования. Советами здесь не поможешь, поэтому мы решили перейти к реальным действиям,
          которые зависят от нас.</p>

        <p>В этот сложный момент команда Параплан готова помочь своим действующим и новым клиентам преодолеть
          сложившийся кризис.</p>

        <p className={styles.header__h4} style={{ textAlign: "center", fontSize: "22px" }}><b>С 23 апреля по 30 июня
          2020 года мы делаем весь функционал системы&nbsp;Параплан полностью бесплатным.</b></p>

        <p>Всем нашим клиентам уже сейчас доступен личный кабинет ученика, с помощью которого вы сможете <b>
          организовать удаленное обучение</b> с домашними заданиями и вебинарами.</p>

        <p>Мы в свою очередь продолжаем работать с удвоенной энергией, чтобы принести Вашему бизнесу еще больше
          пользы.
          В ближайшее время в системе появится много полезных и удобных обновлений:</p>
        <ul>
          <li>Роли пользователей</li>
          <li>IP-телефония</li>
          <li>Обновленное расписание</li>
          <li>Отработки и многое другое</li>
        </ul>
        <p>Сейчас отличное время для того, чтобы задуматься об автоматизации и оптимизации Вашего бизнеса, разобраться в
          деталях и выйти из кризиса еще более сильной компанией. Наш отдел по работе с клиентами проведет обучающие
          вебинары и
          поможет Вам настроить удаленную работу вашего центра.</p>
        <p><b>Мы верим в скорейшее завершение кризиса и продолжаем работать в ваших интересах!</b></p>
        <p>С уважением, Вадим Хамзин.<br/>
          Руководитель проекта Параплан.</p>
      </div>
      <div className={styles.cta}>
        <button type={"button"}
                className={styles.ctaButton}
                onClick={() => openOverlay("special-quarantine-offer", "article", "bottom")}>Оформить
          бесплатный&nbsp;доступ
        </button>
      </div>
    </div>
    <ContactFormComponent/>
  </Layout>
)


export default QuarantineOfferPage
